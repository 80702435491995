import type { THistoryPaymentFindBody } from 'api/merchants/historyPayments/types';
import { PREPAYMENTS_CONSTANTS } from 'utils/constants/prepayments';
import { dateHelpers } from 'utils/helpers/dateHelpers';

import type { IFilterForm } from '../types';

const getFiltersData = (data: IFilterForm) => {
    if (!Object.keys(data).length) {
        return;
    }

    const result: THistoryPaymentFindBody = {};

    if (data.startDate) {
        result.startDate = dateHelpers.formatTo(
            dateHelpers.formatToUtc(data.startDate).toISOString(),
            PREPAYMENTS_CONSTANTS.FILTER_DATE_FORMAT,
        );
    }

    if (data.endDate) {
        result.endDate = dateHelpers.formatTo(
            dateHelpers.formatToUtc(data.endDate).toISOString(),
            PREPAYMENTS_CONSTANTS.FILTER_DATE_FORMAT,
        );
    }

    if (data.searchStr) {
        result.searchStr = data.searchStr;
    }

    if (data.currencyCodes) {
        result.currencyCodes = data.currencyCodes;
    }

    return result;
};

export const filtersHelpers = { getFiltersData };
