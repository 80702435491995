import { notification } from 'antd';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { payoutInvoicesTableFiltersSelectors } from 'store/invoices/payout/payoutInvoicesTableFiltersSelectors';
import { payoutInvoicesTableSelectors } from 'store/invoices/payout/payoutInvoicesTableSelectors';
import { usePayoutInvoiceTable } from 'store/invoices/payout/usePayoutInvoiceTable';
import { usePayoutInvoiceTableFilters } from 'store/invoices/payout/usePayoutInvoiceTableFilters';
import Loader from 'ui/Loader';
import Table from 'ui/Table';
import { dateHelpers } from 'utils/helpers/dateHelpers';
import { getSortParams } from 'utils/helpers/tableHelpers';
import type { TComponent } from 'utils/types/component';

import { useOnce } from 'components/utils/hooks/useOnce';

import type { IPayoutInvoice } from 'models/Invoice/Payout';
import type { TTableSort } from 'models/Table';

import { Filters } from './components/Filters';
import { CustomPagination } from './components/Paginations';
import { COLUMNS } from './columns';
import styles from './PayoutInvoicesTable.module.scss';

const TABLE_VERTICAL_SCROLL_WIDTH = 1600;

export const PayoutInvoicesTable: TComponent = () => {
    const { t } = useTranslation();

    const data = usePayoutInvoiceTable(payoutInvoicesTableSelectors.rows);
    const loading = usePayoutInvoiceTable(
        payoutInvoicesTableSelectors.isLoading,
    );
    const getTable = usePayoutInvoiceTable(
        payoutInvoicesTableSelectors.getTable,
    );
    const exportPayoutInvoices = usePayoutInvoiceTable(
        payoutInvoicesTableSelectors.exportPayoutInvoices,
    );

    const body = usePayoutInvoiceTableFilters(
        payoutInvoicesTableFiltersSelectors.getBody,
    );
    const params = usePayoutInvoiceTableFilters(
        payoutInvoicesTableFiltersSelectors.getParams,
    );
    const getCurrenciesOptions = usePayoutInvoiceTableFilters(
        payoutInvoicesTableFiltersSelectors.getCurrenciesOptions,
    );
    const getPayoutMethodNamesOptions = usePayoutInvoiceTableFilters(
        payoutInvoicesTableFiltersSelectors.getPayoutMethodNamesOptions,
    );
    const getSitesOptions = usePayoutInvoiceTableFilters(
        payoutInvoicesTableFiltersSelectors.getSitesOptions,
    );

    const handleExport = (email: string) => {
        const startDate = body.dateFilter?.createdAtFrom;
        const endDate = body.dateFilter?.createdAtTo;

        if (startDate && endDate) {
            const isWithin14Days = dateHelpers.isWithin14Days(
                startDate,
                endDate,
            );

            if (!isWithin14Days) {
                return notification.error({
                    message: t(
                        'invoices.payout.export.filterWrongDatePeriodError',
                    ),
                });
            }

            exportPayoutInvoices({
                filter: body,
                email,
            });
        } else {
            notification.error({
                message: t('invoices.payout.export.filterOutOfDateError'),
            });
        }
    };

    const handleSortTable = (
        _: number,
        sortParams: TTableSort<IPayoutInvoice>,
    ) => {
        const { property, order } = getSortParams(sortParams);

        const newParams = { ...params, sort: `${property},${order}` };

        getTable(body, newParams);
    };

    useOnce(
        () => true,
        () => {
            getTable(body, params);
            getCurrenciesOptions();
            getPayoutMethodNamesOptions();
            getSitesOptions();
        },
        [],
    );

    return (
        <Loader active={loading}>
            <div className={styles.container}>
                <div className={cn(styles.actionBar, 'actionBar')}>
                    <Filters onExport={handleExport} />
                </div>
                <Table
                    scroll={{ x: TABLE_VERTICAL_SCROLL_WIDTH }}
                    columns={COLUMNS}
                    tableRows={data}
                    onChange={handleSortTable}
                />
                <div className={styles.tableBottom}>
                    <CustomPagination />
                </div>
            </div>
        </Loader>
    );
};
