import { notification } from 'antd';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { historyPaymentsTableFiltersSelectors } from 'store/merchants/historyPaymentsTableFiltersSelectors';
import { historyPaymentsTableSelectors } from 'store/merchants/historyPaymentsTableSelectors';
import { merchantsSelectors } from 'store/merchants/merchantsSelectors';
import { useHistoryPaymentsTable } from 'store/merchants/useHistoryPaymentsTable';
import { useHistoryPaymentsTableFilters } from 'store/merchants/useHistoryPaymentsTableFilters';
import { useMerchants } from 'store/merchants/useMerchants';
import { ExportButton } from 'ui/ExportButton/ExportButton';
import Loader from 'ui/Loader';
import Table from 'ui/Table';
import { dateHelpers } from 'utils/helpers/dateHelpers';
import { getSortParams } from 'utils/helpers/tableHelpers';
import type { TComponent } from 'utils/types/component';

import { useOnce } from 'components/utils/hooks/useOnce';

import type { THistoryPayment } from 'models/Merchant/HistoryPayment';
import type { TTableSort } from 'models/Table';

import { Filters } from './components/Filters';
import { CustomPagination } from './components/Paginations';
import { COLUMNS } from './columns';
import styles from './HistoryPayments.module.scss';

const TABLE_VERTICAL_SCROLL_WIDTH = 2200;

export const HistoryPayments: TComponent = () => {
    const { t } = useTranslation();

    const data = useHistoryPaymentsTable(historyPaymentsTableSelectors.rows);
    const loading = useHistoryPaymentsTable(
        historyPaymentsTableSelectors.isLoading,
    );
    const fetchHistoryPayments = useHistoryPaymentsTable(
        historyPaymentsTableSelectors.getTable,
    );
    const getMerchant = useMerchants(merchantsSelectors.getMerchant);
    const exportPrepayments = useHistoryPaymentsTable(
        historyPaymentsTableSelectors.exportPrepayments,
    );

    const body = useHistoryPaymentsTableFilters(
        historyPaymentsTableFiltersSelectors.getBody,
    );
    const params = useHistoryPaymentsTableFilters(
        historyPaymentsTableFiltersSelectors.getParams,
    );

    const handleExport = () => {
        const startDate = body.startDate;
        const endDate = body.endDate;

        if (startDate && endDate) {
            const isWithin14Days = dateHelpers.isWithin14Days(
                startDate,
                endDate,
            );

            if (!isWithin14Days) {
                return notification.error({
                    message: t(
                        'balance.historyPaymentsExport.filterWrongDatePeriodError',
                    ),
                });
            }

            exportPrepayments({
                ...body,
                startDate,
                endDate,
            });
        } else {
            notification.error({
                message: t(
                    'balance.historyPaymentsExport.filterOutOfDateError',
                ),
            });
        }
    };

    const handleSortTable = (
        _: number,
        sortParams: TTableSort<THistoryPayment>,
    ) => {
        const { property, order } = getSortParams(sortParams);

        const newParams = { ...params, sort: `${property},${order}` };

        fetchHistoryPayments(body, newParams);
    };

    useOnce(
        () => true,
        () => {
            fetchHistoryPayments(body, params);
            getMerchant();
        },
        [],
    );

    return (
        <Loader active={loading}>
            <div className={styles.container}>
                <div className={cn(styles.actionBar, 'actionBar')}>
                    <Filters />
                    <div className={styles.rightControls}>
                        <ExportButton onClick={handleExport} />
                    </div>
                </div>
                <Table
                    scroll={{ x: TABLE_VERTICAL_SCROLL_WIDTH }}
                    columns={COLUMNS}
                    tableRows={data}
                    onChange={handleSortTable}
                />
                <div className={styles.tableBottom}>
                    <CustomPagination />
                </div>
            </div>
        </Loader>
    );
};
