import { lazy, Suspense, useState } from 'react';
import { FundViewOutlined } from '@ant-design/icons';
import { message, notification } from 'antd';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { balanceTableFiltersSelectors } from 'store/merchants/balanceTableFiltersSelectors';
import { balanceTableSelectors } from 'store/merchants/balanceTableSelectors';
import { merchantsSelectors } from 'store/merchants/merchantsSelectors';
import { useBalanceTable } from 'store/merchants/useBalanceTable';
import { useBalanceTableFilters } from 'store/merchants/useBalanceTableFilters';
import { useMerchants } from 'store/merchants/useMerchants';
import { ExportButton } from 'ui/ExportButton/ExportButton';
import Loader from 'ui/Loader';
import Table from 'ui/Table';
import { getSortParams } from 'utils/helpers/tableHelpers';
import type { TNullable } from 'utils/types/common';
import type { TComponent } from 'utils/types/component';

import { useOnce } from 'components/utils/hooks/useOnce';

import type { TBalance } from 'models/Merchant/Balance';
import type { TTableColumn, TTableSort } from 'models/Table';

import { Filters } from './components/Filters';
import { CustomPagination } from './components/Paginations';
import styles from './BalanceTable.module.scss';

const DetailsModal = lazy(() => import('./DetailsModal'));

const TABLE_VERTICAL_SCROLL_WIDTH = 1800;

export const BalanceTable: TComponent = () => {
    const { t } = useTranslation();

    const data = useBalanceTable(balanceTableSelectors.rows);
    const loading = useBalanceTable(balanceTableSelectors.isLoading);
    const fetchBalances = useBalanceTable(balanceTableSelectors.getTable);
    const getMerchant = useMerchants(merchantsSelectors.getMerchant);
    const exportBalances = useBalanceTable(
        balanceTableSelectors.exportBalances,
    );
    const getBalanceDetail = useBalanceTable(
        balanceTableSelectors.getBalanceDetail,
    );
    const balanceDetail = useBalanceTable(balanceTableSelectors.balanceDetail);

    const params = useBalanceTableFilters(
        balanceTableFiltersSelectors.getParams,
    );
    const detailsParams = useBalanceTableFilters(
        balanceTableFiltersSelectors.getDetailsParams,
    );
    const body = useBalanceTableFilters(balanceTableFiltersSelectors.getBody);
    const getPaymentMethodNamesOptions = useBalanceTableFilters(
        balanceTableFiltersSelectors.getPaymentMethodNamesOptions,
    );
    const getPayoutMethodNamesOptions = useBalanceTableFilters(
        balanceTableFiltersSelectors.getPayoutMethodNamesOptions,
    );

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentBalance, setCurrentBalance] =
        useState<TNullable<TBalance>>(null);
    const [detailsError, setDetailsError] = useState<TNullable<string>>(null);

    const handleOpenModal = async (balance: TBalance) => {
        try {
            await getBalanceDetail(
                {
                    ...body,
                    currencyCode: balance.currencyCode,
                },
                detailsParams,
            );
            setCurrentBalance(balance);
            setIsModalVisible(true);
            setDetailsError(null);
        } catch (error) {
            setDetailsError(t('balance.errors.errorLoadingDetails'));
            message.error(t('balance.errors.errorLoadingDetails'));
        }
    };

    const handleCloseDetailsModal = () => {
        setIsModalVisible(false);
    };

    const handleExport = () => {
        if (body.date) {
            exportBalances({
                ...body,
                date: body.date,
            });
        } else {
            notification.error({
                message: t('balance.balanceExport.filterOutOfDateError'),
            });
        }
    };

    const handleSortTable = (_: number, sortParams: TTableSort<TBalance>) => {
        const { property, order } = getSortParams(sortParams);

        const newParams = { ...params, sort: `${property},${order}` };

        fetchBalances(body, newParams);
    };

    useOnce(
        () => true,
        () => {
            fetchBalances(body, params);
            getPaymentMethodNamesOptions();
            getPayoutMethodNamesOptions();
            getMerchant();
        },
        [],
    );

    const COLUMNS: TTableColumn<TBalance>[] = [
        {
            title: 'balance.balanceTable.details',
            dataIndex: 'details',
            key: 'details',
            align: 'center',
            render: (_text, record) => (
                <div
                    className={styles.detailIcon}
                    onClick={() => handleOpenModal(record)}
                >
                    <FundViewOutlined />
                </div>
            ),
        },
        {
            key: 'merchantName',
            dataIndex: 'merchantName',
            title: 'balance.balanceTable.merchantName',
        },
        {
            key: 'currencyCode',
            dataIndex: 'currencyCode',
            title: 'balance.balanceTable.currency',
        },

        {
            key: 'currentBalance',
            dataIndex: 'currentBalance',
            title: 'balance.balanceTable.currentBalance',
            sorter: true,
        },
        {
            key: 'currentBalanceInUsd',
            dataIndex: 'currentBalanceInUsd',
            title: 'balance.balanceTable.currentBalanceUSD',
            sorter: true,
        },

        {
            key: 'previousDayBalance',
            dataIndex: 'previousDayBalance',
            title: 'balance.balanceTable.previousDayBalance',
            sorter: true,
        },

        {
            key: 'todayDepositAmount',
            dataIndex: 'todayDepositAmount',
            title: 'balance.balanceTable.todayDepositAmount',
            sorter: true,
        },

        {
            key: 'todayPayoutAmount',
            dataIndex: 'todayPayoutAmount',
            title: 'balance.balanceTable.todayDepositAmount',
            sorter: true,
        },

        {
            key: 'todayDepositCommissionAmount',
            dataIndex: 'todayDepositCommissionAmount',
            title: 'balance.balanceTable.todayDepositCommissionAmount',
            sorter: true,
        },

        {
            key: 'todayPayoutCommissionAmount',
            dataIndex: 'todayPayoutCommissionAmount',
            title: 'balance.balanceTable.todayPayoutCommissionAmount',
            sorter: true,
        },
        {
            key: 'todayPrepaymentAmount',
            dataIndex: 'todayPrepaymentAmount',
            title: 'balance.balanceTable.todayPrepaymentAmount',
            sorter: true,
        },
        {
            key: 'todayPrepaymentCommissionAmount',
            dataIndex: 'todayPrepaymentCommissionAmount',
            title: 'balance.balanceTable.todayPrepaymentCommissionAmount',
            sorter: true,
        },
    ];

    return (
        <Loader active={loading}>
            <div className={styles.container}>
                <div className={cn(styles.actionBar, 'actionBar')}>
                    <Filters />
                    <div className={styles.rightControls}>
                        <ExportButton onClick={handleExport} />
                    </div>
                </div>
                <Table
                    scroll={{ x: TABLE_VERTICAL_SCROLL_WIDTH }}
                    columns={COLUMNS}
                    onChange={handleSortTable}
                    tableRows={data}
                />
                <div className={styles.tableBottom}>
                    <CustomPagination />
                </div>
                {!detailsError && currentBalance && (
                    <Suspense fallback={<Loader active />}>
                        <DetailsModal
                            isModalVisible={isModalVisible}
                            onCloseModal={handleCloseDetailsModal}
                            balanceDetails={balanceDetail}
                            currentBalance={currentBalance}
                        />
                    </Suspense>
                )}
            </div>
        </Loader>
    );
};

export default BalanceTable;
