import { useTranslation } from 'react-i18next';
import { prepaymentsHelpers } from 'utils/helpers/prepayments';
import type { TComponent } from 'utils/types/component';

import type { THistoryPayment } from 'models/Merchant/HistoryPayment';

interface IReasonProps {
    balanceChangeReason: THistoryPayment['balanceChangeReason'];
}

export const Reason: TComponent<IReasonProps> = (props) => {
    const { balanceChangeReason } = props;

    const { t } = useTranslation();

    const key = prepaymentsHelpers.getReason(balanceChangeReason);

    return <>{t(key)}</>;
};
