import { PREPAYMENTS_CONSTANTS } from 'utils/constants/prepayments';
import { dateHelpers } from 'utils/helpers/dateHelpers';

import type { THistoryPayment } from 'models/Merchant/HistoryPayment';
import type { TTableColumn } from 'models/Table';

import { Reason } from './components/Reason';

export const COLUMNS: TTableColumn<THistoryPayment>[] = [
    {
        dataIndex: 'identity',
        key: 'identity',
        title: 'balance.historyPaymentsTable.identity',
    },

    {
        dataIndex: 'balanceChangeReason',
        key: 'balanceChangeReason',
        title: 'balance.historyPaymentsTable.reason',
        render: (_, record) => (
            <Reason balanceChangeReason={record.balanceChangeReason} />
        ),
    },

    {
        dataIndex: 'currencyCode',
        key: 'currencyCode',
        title: 'balance.historyPaymentsTable.currency',
    },

    {
        dataIndex: 'inputAmount',
        key: 'inputAmount',
        title: 'balance.historyPaymentsTable.inputAmount',
        sorter: true,
    },

    {
        dataIndex: 'adjustmentFee',
        key: 'adjustmentFee',
        title: 'balance.historyPaymentsTable.adjustmentFee',
        sorter: true,
    },

    {
        dataIndex: 'finalAmount',
        key: 'finalAmount',
        title: 'balance.historyPaymentsTable.finalAmount',
        sorter: true,
    },

    {
        dataIndex: 'createdBy',
        key: 'createdBy',
        title: 'balance.historyPaymentsTable.author',
    },
    {
        dataIndex: 'createdAt',
        key: 'createdAt',
        title: 'balance.historyPaymentsTable.createdAt',
        render: (_, record) =>
            dateHelpers.formatToLocalTimezone(
                record.createdAt,
                PREPAYMENTS_CONSTANTS.VIEW_DATE_FORMAT,
            ),
    },
    {
        dataIndex: 'comment',
        key: 'comment',
        title: 'balance.historyPaymentsTable.comment',
    },
];
